import { emptyList, ItemList } from '../../../common/models/ItemList'
import { Observable, of } from 'rxjs'
import { Container, IInit } from '../../../common/container/Container'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Query } from '../../../common/api/Query'
import { PatientReceptiveness, PatientReceptivenessDTO, PatientQuery, toModel } from '../models/PatientReceptiveness'
import { PatientContainerConfig } from '../container'
import { prepareURL } from '../../../common/api/http-helpers'
import { catchError, map } from 'rxjs/operators'
import {PatientPGD, PatientPGDDTO, toModelPGD} from "../models/PatientPGD";

export interface IPatientApi extends IInit {
  getByID(id: string): Observable<PatientReceptiveness | undefined>
  getByIDPGD(id: string): Observable<PatientPGD | undefined>
  getBySampleName(sampleName: string) : Observable<PatientReceptiveness| undefined>
  getFilteredList(q: Query<PatientQuery>): Observable<ItemList<PatientReceptiveness>>
  add(e: PatientReceptivenessDTO): Observable<PatientReceptiveness | undefined>
  addPGD(e: PatientPGDDTO): Observable<PatientPGD | undefined>
  update(e: PatientReceptivenessDTO): Observable<PatientReceptiveness | undefined>
  updatePGD(e: PatientPGDDTO): Observable<PatientPGD | undefined>
  delete(id: string): Observable<boolean>
  getFilteredListPGD(q: Query<PatientQuery>): Observable<ItemList<PatientPGD>>
}

export class PatientApi implements IPatientApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as PatientContainerConfig).moduleFullUrlPatient
  }

  add(e: PatientReceptivenessDTO): Observable<PatientReceptiveness | undefined> {
    return this._httpClient.post<PatientReceptiveness>({ url: this._url, body: e }).pipe(
      map<PatientReceptivenessDTO, PatientReceptiveness>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: this._url + '/' + id }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<PatientReceptiveness | undefined> {
    return this._httpClient.get<PatientReceptiveness>({ url: `${this._url}/${id}` }).pipe(
      map<PatientReceptivenessDTO, PatientReceptiveness>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getByIDPGD(id: string): Observable<PatientPGD | undefined> {
    return this._httpClient.get<PatientPGD>({ url: `${this._url}/${id}` }).pipe(
      map<PatientPGDDTO, PatientPGD>((d) => toModelPGD(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getBySampleName(sampleName: string): Observable<PatientReceptiveness | undefined>{
    return this._httpClient.get<PatientReceptiveness>({url: `${this._url}/${sampleName}`}).pipe(
      map<PatientReceptivenessDTO, PatientReceptiveness>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getFilteredList(q: Query<PatientQuery>): Observable<ItemList<PatientReceptiveness>> {
    return this._httpClient.get<ItemList<PatientReceptiveness>>({ url: prepareURL(this._url, q) }).pipe(
      map<ItemList<PatientReceptivenessDTO>, ItemList<PatientReceptiveness>>((dto) => {
        const itemList = emptyList<PatientReceptiveness>()
        itemList.count = dto.count
        itemList.items = dto.items.map((d) => toModel(d))
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<PatientReceptiveness>())
      })
    )
  }

  update(e: PatientReceptivenessDTO): Observable<PatientReceptiveness | undefined> {
    return this._httpClient.put<PatientReceptiveness>({ url: this._url, body: e }).pipe(
      map<PatientReceptivenessDTO, PatientReceptiveness>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  /* PGD */

    getFilteredListPGD(q: Query<PatientQuery>): Observable<ItemList<PatientPGD>> {
      
        return this._httpClient.get<ItemList<PatientPGD>>({ url: prepareURL(this._url, q) }).pipe(
            map<ItemList<PatientPGDDTO>, ItemList<PatientPGD>>((dto) => {
                const itemList = emptyList<PatientPGD>()
                itemList.count = dto.count
                itemList.items = dto.items.map((d) => toModelPGD(d))
                return itemList
            }),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(emptyList<PatientPGD>())
            })
        )
    }

    addPGD(e: PatientPGDDTO): Observable<PatientPGD | undefined> {
    
      return this._httpClient.post<PatientPGD>({ url: this._url+"/patientpgd", body: e }).pipe(
        map<PatientPGDDTO, PatientPGD>((d) => toModelPGD(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
    }

    updatePGD(e: PatientPGDDTO): Observable<PatientPGD | undefined> {
      
      return this._httpClient.put<PatientPGD>({ url: this._url+"/pgd", body: e }).pipe(
        map<PatientPGDDTO, PatientPGD>((d) => toModelPGD(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
    }
}
