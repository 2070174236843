import {RouteProps} from '../../routes/AppRouter'
import {FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField} from '@material-ui/core'
import {useForm} from '../../common/utils/form-generation/useForm'
import {useTranslation} from 'react-i18next'
import {navigate} from '@reach/router'
import {URL_PATIENTS} from '../../routes/routes-constants'
import {ChangeEvent, FormEvent, useEffect, useRef, useState} from 'react'
import {v4 as uuidv4} from 'uuid'
import {FormAction, FormActions} from '../../common/utils/form-generation'
import {Query, QueryParam} from '../../common/api/Query'
import {Clinic, ClinicQuery} from '../../modules/clinics/models/Clinic'
import {getClinicContainer} from '../../container/clinic-modules'
import {ClinicService} from '../../modules/clinics/services/ClinicService'
import {CLINIC_SERVICE_KEY} from '../../modules/clinics'
import {getPatientContainer} from '../../container/patient-module'
import {PatientService} from '../../modules/patients/services/PatientService'
import {PATIENT_SERVICE_KEY} from '../../modules/patients'
import {PatientReceptiveness, PatientReceptivenessDTO} from '../../modules/patients/models/PatientReceptiveness'
import {EntitySelect} from '../common/EntitySelect'
import {userGenders} from '../../modules/users/enums/GenderType'
import {useStyles} from '../../components/entity-page/EntityPage.styles'
import {getAuthContainer} from '../../container/auth-modules'
import {AuthService} from '../../modules/auth/services/AuthService'
import {AUTH_SERVICE_KEY} from '../../modules/auth'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import {Permission} from '../../common/enums/Permissions'
import {optionPatientLanguage} from '../../modules/users/enums/Language'

const clinicService = getClinicContainer().get<ClinicService>(CLINIC_SERVICE_KEY)

const patientService = getPatientContainer().get<PatientService>(PATIENT_SERVICE_KEY)
const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)

export type FormProps = RouteProps & {
    patient: PatientReceptiveness | undefined
}

export const Form = (props: FormProps) => {
    const {t} = useTranslation()
    const classes = useStyles({})
    const [selectedFile, setSelectedFile] = useState<File>()
    const [clinics, setClinics] = useState<Clinic[]>([])

    useEffect(() => {
        if (!props.patient) {
            return
        }
        setData(props.patient.toDTO())
    }, [props.patient])

    useEffect(() => {
        const query = []
        if (!authService.get().permissions.includes(Permission.viewAll)) {
            query.push(new QueryParam<ClinicQuery>('ids', authService.get().clinics))
        } else {
            clinicService.getAllClinics().subscribe((res)=> setClinics(res.items))
        }
        clinicService
            .getFilteredList(
                new Query({
                    query,
                    sort: [{field: 'name'}],
                }),
            )
            .subscribe((res) =>{
                setClinics(res.items)
            } )
    }, [])

    const {handleChange, handleSubmit, data, setData, errors} = useForm<PatientReceptivenessDTO>({
        validations: {
            clinicID: {
                required: {
                    value: true,
                    message: t('clinicNotValidError'),
                },
            },
            idPatient: {
                required: {
                    value: true,
                    message: t('idPatientNotValidError'),
                },
            },
            firstName: {
                required: {
                    value: true,
                    message: t('firstNameNotValidError'),
                },
            },
            lastName: {
                required: {
                    value: true,
                    message: t('lastNameNotValidError'),
                },
            },
            gender: {
                required: {
                    value: true,
                    message: t('genderNotValidError'),
                },
            },
            dob: {
                required: {
                    value: true,
                    message: t('dobNotValidError'),
                },
            }
        },

        onSubmit: () => {
            function storeCb(value: PatientReceptiveness | undefined) {
                if (value) {
                    if (selectedFile) {
                    } else {
                        goToPatients().then()
                    }
                }
            }

            if (!data.id) {
                patientService
                    .add({
                        ...data,
                        id: uuidv4(),
                    })
                    .subscribe((value) => storeCb(value))
                return
            }

            patientService
                .update({...data})
                .subscribe((value) => storeCb(value))
        },

        initialValues: props.patient && props.patient.toDTO(),
    })

    const inputFile = useRef<HTMLInputElement | null>(null)

    const goToPatients = () => navigate(URL_PATIENTS)

    const actions: FormAction[] = [
        {
            label: t('back'),
            handleAction: () => goToPatients(),
        },
    ]

const handleSend = (e: FormEvent<HTMLFormElement>) => {

   const sampleName: string = data.firstName + data.lastName
   handleChange('sampleName', sampleName)
   handleSubmit(e)

}


    return (
        <form onSubmit={handleSend}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <EntitySelect
                        name={'name'}
                        value={data && data.clinicID}
                        options={clinics}
                        error={errors['clinicID']}
                        onChange={(value) => handleChange('clinicID', value && value.id)}
                        label={t('clinic')}
                        pk={'id'}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant={'outlined'}
                        error={errors['idPatient'] !== undefined}
                        id={'name'}
                        type={'string'}
                        onChange={(event) => handleChange('idPatient', event.target.value)}
                        value={(data && data.idPatient) || ''}
                        label={t('idPatient')}
                        helperText={errors['idPatient']}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant={'outlined'}
                        error={errors['firstName'] !== undefined}
                        id={'firstName'}
                        type={'string'}
                        onChange={(event) => handleChange('firstName', event.target.value)}
                        value={(data && data.firstName) || ''}
                        label={t('firstName')}
                        helperText={errors['firstName']}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant={'outlined'}
                        error={errors['lastName'] !== undefined}
                        id={'lastName'}
                        type={'string'}
                        onChange={(event) => handleChange('lastName', event.target.value)}
                        value={(data && data.lastName) || ''}
                        label={t('lastName')}
                        helperText={errors['lastName']}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth variant='outlined' error={errors['dob'] !== undefined}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                style={{width: '100%'}}
                                id='dob'
                                autoOk
                                inputVariant={'outlined'}
                                format='dd/MM/yyyy'
                                value={data.dob || null}
                                onChange={(newDate) => setData(Object.assign({...data}, {dob: newDate}))}
                                required={false}
                                size={'small'}
                                label={t('dob')}
                            />
                        </MuiPickersUtilsProvider>
                        <FormHelperText>{errors['dob']}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth variant='outlined' error={errors['gender'] !== undefined}>
                        <InputLabel id='inherit-label'>{t('gender')}</InputLabel>
                        <Select
                            labelId='gender-label'
                            id='gender'
                            fullWidth
                            style={{textAlign: 'left'}}
                            value={(data && data.gender) || ''}
                            onChange={(event) =>
                                handleChange('gender', event.target.value && +(event.target.value as string))
                            }
                            label={t('gender')}>
                            {Object.entries(userGenders()).map(([key, value]) => (
                                <MenuItem value={key}>{value}</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{errors['gender']}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" error={errors['language'] !== undefined}>
                        <InputLabel id="language-label">{t('language')}</InputLabel>
                        <Select
                            labelId="language-label"
                            id="language"
                            fullWidth
                            style={{textAlign: 'left'}}
                            value={(data && data.language) || ''}
                            onChange={(event) => handleChange('language', event.target.value)}
                            label={t('language')}>
                            {Object.keys(optionPatientLanguage()).map((l) => (
                                <MenuItem value={l} key={l}>
                                    {t(l)}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{errors['language']}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        error={errors['geneticDiseaseHistory'] !== undefined}
                        fullWidth
                        id='geneticDiseaseHistory'
                        label={t('geneticDiseaseHistory')}
                        onChange={(event) => handleChange('geneticDiseaseHistory', event.target.value)}
                        multiline
                        rows={4}
                        value={data && data.geneticDiseaseHistory}
                        variant='outlined'
                        helperText={errors['geneticDiseaseHistory']}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
            </Grid>
            <FormActions actions={actions}/>
        </form>
    )
}