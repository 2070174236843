import { COLOR_CLINICS, COLOR_PRIMARY } from '../../routes/color-constants'
import { Field } from '../../components/table'
import React, { ChangeEvent, useEffect, useState, useRef } from 'react'
import { Clinic } from '../../modules/clinics/models/Clinic'
import { Actions, Pager, Sort } from '../../components/table/types'
import deleteIcon from '../../assets/table-icons/delete-icon.svg'
import { useTranslation } from 'react-i18next'
import { Query, SortParam } from '../../common/api/Query'
import downloadIcon from '../../assets/table-icons/download-icon.svg'
import { Box, Button, CircularProgress, Modal } from '@material-ui/core'
import { useStyles } from '../matchings/Matching.styles'
import { FormAction, FormActions } from '../../common/utils/form-generation'
import { commonStyles } from '../../common/styles/Styles'
import { getFileContainer } from 'container/file-module'
import { FileService } from 'modules/files/services/FileService'
import { FILE_SERVICE_KEY } from 'modules/files'
import { Bedfile } from 'modules/files/models/File'
import { useSnackbar } from 'notistack'
import { v4 as uuidv4 } from 'uuid'
import { dataToBase64, downloadFile } from '../../common/files/file'
import { getAuthContainer } from 'container/auth-modules'
import { AuthService } from 'modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from 'modules/auth'
import { Pagination } from 'components/table/Pagination'
import { Table, TableContainer } from '@material-ui/core'
import { Head } from 'components/table/Head'
import { TableCell, TableRow, TextField } from '@material-ui/core'
import { GenericTooltip } from 'components/generic-tooltip'
import { navigate } from '@reach/router'
import { URL_SCRIPT } from 'routes/routes-constants'


const fileService = getFileContainer().get<FileService>(FILE_SERVICE_KEY)
const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)

export const BedfilesPg = () => {
  const { t } = useTranslation()
  const classes = useStyles({ color: COLOR_PRIMARY })
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [open, setOpen] = useState<boolean>(false)
  const [pager, setPager] = useState<Pager>()
  const [page, setPage] = useState<number>(0)
  const [count, setCount] = useState<number>(0)
  const [itemsPerPage, setItemsPerPage] = useState<number>(10)
  const [items, setItems] = useState<Bedfile[]>([])
  const [sort, setSort] = useState<SortParam<Clinic>>({
    field: 'creationDate',
    desc: true,
  })
  const styles = commonStyles()
  const [currentBedfile, setCurrentBedfile] = useState<Bedfile>()
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const { enqueueSnackbar } = useSnackbar()
  const loggedUser = authService.get()
  const [err, setErr] = useState<string>()
  const [isLoadingInm, setIsLoadingInm] = useState<boolean>(true)


  const goToScript = () => navigate(URL_SCRIPT)

  useEffect(() => {
    if (!isLoading) return
    fileService
      .getBedfiles(
        new Query({
          pager: { offset: page * itemsPerPage, limit: itemsPerPage },
          sort: [{ field: sort.field, desc: sort.desc }],
        })
      )
      .subscribe((res) => {
        setIsLoading(false)
        setIsLoadingInm(false)
        let auxItems: any[] = []
       const formatFileBed = /^PGSeq_(\w+)_(\w+)\.(\d+)\.(\w+)*/
       for(let i = 0; i < res.items.length; i++){
        if(formatFileBed.test(res.items[i].fileName)){
          auxItems.push(res.items[i]);
       }}
      
        setItems(auxItems)
        setCount(res.count)
      })
  }, [isLoading])

  useEffect(() => {
    setIsLoading(true)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: itemsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, itemsPerPage])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setItemsPerPage(10)
      return
    }
    setItemsPerPage(Number.parseInt(event.target.value))
  }

  const fields: Field<Bedfile>[] = [
    {
      sortable: true,
      searchable: true,
      label: t('name'),
      name: 'name',
    },
    {
      sortable: true,
      searchable: true,
      label: t('gene'),
      name: 'gene',
    },
    {
      sortable: true,
      searchable: true,
      label: t('fileName'),
      name: 'fileName',
    },
  ]

  const selectBedfile = (c: Bedfile) => {
    setCurrentBedfile(c)
    setOpen(true)
  }

  const deleteBedfile = () => {
    fileService.deleteBedfile(currentBedfile?.fileID || '').subscribe(() => {
      setIsLoading(true)
    })
  }

  const downloadBedfile = (i: Bedfile) => {
    downloadFile(i.fileName, '', i.data)
  }

  const actions: Actions<Bedfile> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: downloadBedfile,
        icon: downloadIcon,
        label: t('download2'),
      },
      {
        handler: selectBedfile,
        icon: deleteIcon,
        label: t('delete'),
      },
    ],
  }

  const sortable: Sort<Bedfile> = {
    name: sort.field,
    direction: sort.desc ? 'desc' : 'asc',
    handleSort: (field) => {
      setSort({ field: field, desc: sort.field === field ? !sort.desc : true })
      setIsLoading(true)
    },
  }

  const handleDelete = () => {
    setOpen(false)
    deleteBedfile()
  }

  const closeModal = () => {
    setOpen(false)
    setCurrentBedfile(undefined)
  }

  const actionsDelete: FormAction[] = [
    {
      label: t('close'),
      handleAction: closeModal,
    },
  ]

  const handleUpload = async (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    
    const files = (event.target as HTMLInputElement).files
    const file = files && files.length && files[0]
    if (file) {
      if (file.name.substring(file.name.length - 3, file.name.length) !== 'bed') {
        enqueueSnackbar(t('fileMustHaveBedExtension'), { variant: 'error' })
        return
      }
      
      const fileData = await dataToBase64(file)
      const formatFileBed = /^PGSeq_(\w+)_(\w+)\.(\d+)\.(\w+)*/
      if (!formatFileBed.test(file.name)) {
        setErr(t('fileMustHaveFormat'))
        return
      }else{
        setIsLoadingInm(true)
        
      fileService
        .uploadBedFile({
          id: uuidv4(),
          name: file.name,
          data: fileData,
          size: file.size,
          mimeType: file.type,
          extension: file.type.split('/')[1],
          ownerID: loggedUser.id,
          pgType: 'pgsec'
        })
        .subscribe(() => {
          setIsLoadingInm(false)
          setIsLoading(true)
        })}
    }
  }

  const changeFile = () => {
    fileInputRef.current?.click()
  }

  const handleUpdate = (index: number) => {
    fileService.updateBedfile(items[index]?.toDTO()).subscribe((res)=> {
      setIsLoading(true)
    })
  }

  const handleChangeName = (name: string, index: number) => {
    let aux = [...items]
    let bedfile = aux[index]
    bedfile.name = name
    aux[index] = bedfile
    setItems(aux)
  }

  return (
    <>
      {!isLoading && (
        <>
          <Box justifyContent={'space-between'} mb={2}>
            <Button
              className={classes.button}
              variant={'contained'}
              onClick={changeFile}
              style={{ float: 'right' }}>
              {t('create')}
            </Button>
          </Box>
          {!isLoadingInm ? ( 
              <>
            <TableContainer>
            <Table>
              
              <Head
                fields={fields}
                sort={sortable}
                actions={actions}
                styleHeader={{ color: COLOR_CLINICS }}
              />
            
              {items.map((item, i) => (
                <TableRow key={'id'}>
                  <TableCell key={fields[0].name as string}>
                    <TextField
                      fullWidth
                      id="name"
                      type={'text'}
                      label={t('name')}
                      value={item.name}
                      onChange={(e)=> handleChangeName(e.target.value, i)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onBlur={()=> handleUpdate(i)}
                    />
                  </TableCell>
                  <TableCell key={fields[1].name as string}>
                    {fields[1].renderFunc
                      ? fields[1].renderFunc(fields[1], item)
                      : item[fields[1].name] && item[fields[1].name].toString()}
                  </TableCell>

                  <TableCell key={fields[2].name as string}>
                    {fields[2].renderFunc
                      ? fields[2].renderFunc(fields[2], item)
                      : item[fields[2].name] && item[fields[2].name].toString()}
                  </TableCell>

                  {actions && actions.items?.length > 0 && (
                    <TableCell key="actions-table">
                      <Box display="flex" justifyContent="space-between">
                        {actions.items.map((a) => {
                          if (a.hidden && a.hidden(item)) {
                            return <></>
                          }
                          return (
                            <GenericTooltip
                              values={[t(a.label || a.icon)]}
                              icon={
                                <img
                                  src={a.icon}
                                  className={classes.icon}
                                  key={'id'}
                                  onClick={() => a.handler(item)}
                                />
                              }
                              noUseFab={true}
                            />
                          )
                        })}
                      </Box>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </Table>
            </TableContainer>
             </>
            ) : (
              <Box style={{ width: '100%', justifyContent: 'center' }}>
                <CircularProgress />
              </Box>
            )}
    
          {pager && <Pagination {...pager} />}


          <Button
              className={classes.button}
              variant={'contained'}
              onClick={goToScript}
              style={{ marginTop: "20px" }}>
              {t('back')}
          </Button>

          <Modal open={open}>
            <Box className={styles.modal}>
              <form onSubmit={handleDelete}>
                <Box mt={2} textAlign={'justify'}>
                  {t('bedfileConfirmationMessage')}
                </Box>
                <FormActions actions={actionsDelete} message={t('confirm')} />
              </form>
            </Box>
          </Modal>
          <input
            onChange={(event) => handleUpload(event)}
            multiple={false}
            ref={fileInputRef}
            type="file"
            hidden
          />
        </>
      )}
    </>
  )
}
