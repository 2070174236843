import {useTranslation} from "react-i18next";
import {useStyles} from "../../matchings/Matching.styles";
import {COLOR_PRIMARY} from "../../../routes/color-constants";
import React, {useEffect, useState} from "react";
import {Actions, Field, Pager, Search, SearchValue, Sort} from "../../../components/table/types";
import {PatientQuery} from "../../../modules/patients/models/PatientPGD";
import {BoolQueryParam, Query, QueryParam, QueryParamN, SortParam} from "../../../common/api/Query";
import {PatientPGD} from "../../../modules/patients/models/PatientPGD";
import {getPatientContainer} from "../../../container/patient-module";
import {PatientService} from "../../../modules/patients/services/PatientService";
import {PATIENT_SERVICE_KEY} from "../../../modules/patients";
import {getAuthContainer} from "../../../container/auth-modules";
import {AuthService} from "../../../modules/auth/services/AuthService";
import {AUTH_SERVICE_KEY} from "../../../modules/auth";
import {Permission} from "../../../common/enums/Permissions";
import {userGenders} from "../../../modules/users/enums/GenderType";
import seeIcon from "../../../assets/table-icons/see-icon.svg";
import editIcon from "../../../assets/table-icons/edit-icon.svg";
import deleteIcon from "../../../assets/table-icons/delete-icon.svg";
import {navigate} from "@reach/router";
import {URL_PATIENT_PGD, URL_PATIENT_PGD_SHOW, URL_PATIENT_PGSEC, URL_PATIENT_PGSEC_SHOW} from "../../../routes/routes-constants";
import {Box, Button, Modal} from "@material-ui/core";
import {AppTable} from "../../../components/table";
import { FormAction, FormActions } from "common/utils/form-generation";
import { commonStyles } from "common/styles/Styles";

const patientService = getPatientContainer().get<PatientService>(PATIENT_SERVICE_KEY)
const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)

const searcherQuery = (
    svs: SearchValue<PatientQuery>[],
): QueryParam<PatientQuery>[] | QueryParamN<PatientQuery>[] =>
    svs.filter((sv) => sv.value).map((sv) => ({name: sv.name, value: sv.value as string}))


export const Table = () => {
    const {t} = useTranslation()
    const classes = useStyles({color: COLOR_PRIMARY})
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [pager, setPager] = useState<Pager>()
    const [page, setPage] = useState<number>(0)
    const [count, setCount] = useState<number>(0)
    const [itemsPerPage, setItemsPerPage] = useState<number>(10)
    const [items, setItems] = useState<PatientPGD[]>([])
    const [sort, setSort] = useState<SortParam<PatientPGD>>({
        field: 'idPatient',
        desc: true,
    })
    const [open, setOpen] = useState<boolean>(false)
    const [currentPatientPGD, setCurrentPatientPGD] = useState<PatientPGD>()
    const loggedUser = authService.get()
    const [searcher, setSearcher] = useState<SearchValue<PatientQuery>[]>([
        {
            name: 'idPatient' ,
            label: loggedUser.language === 'es' ? t('search') + ' ' + t('nhcId') : 'Search by NHC/ID ',
        },
        {
            name: 'firstName',
            label: loggedUser.language === 'es' ? t('search') + ' ' + t('byFirstName') : 'Search by First Name',
        },
        {
            name: 'lastName',
            label: loggedUser.language === 'es' ? t('search') + ' ' + t('byLastName') : 'Search by Last Name',
        },
        {
            name: 'clinicName',
            label: loggedUser.language === 'es' ? t('search') + ' ' + t('clinicName') : "Search by Clinic's Name",
        }
    ])

    const [patiens, setPatiens] = useState<Map<string, PatientPGD>>(new Map())

    useEffect(() => {
        if (!isLoading || !authService.get().clinics.length) {
            return
        }
        setIsLoading(true)
        const query = [...searcherQuery(searcher)]
        query.push({
            name:"pgsec",
            value: new BoolQueryParam(true),
        })
        query.push({
            name:"pgd",
            value: new BoolQueryParam(false),
        })
        if (!authService.get().permissions.includes(Permission.viewAll)) {
            query.push(new QueryParam<PatientQuery>('clinics', authService.get().clinics))
        }
        patientService
            .getFilteredListPGD(
                new Query({
                    pager: {offset: page * itemsPerPage, limit: itemsPerPage},
                    query,
                    sort: [{field: sort.field, desc: sort.desc}],
                }),
            )
            .subscribe((res) => {
                setIsLoading(false)
                setItems(res.items)
                setCount(res.count)
                const tmpMapPatiens = new Map<string, PatientPGD>()
                res.items.forEach((i) => i.clinicID && tmpMapPatiens.set(i.id, i))
                setPatiens(tmpMapPatiens)
                
            })
    }, [isLoading])

    useEffect(() => {
        setIsLoading(true)
        setPager({
            page,
            count,
            handleChangePage: handlePaginationChange,
            rowsPerPage: itemsPerPage,
            handleChangeRowsPerPage,
        })
    }, [page, count, itemsPerPage])

    const handlePaginationChange = (event: unknown, value: number) => setPage(value)

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (Number.isNaN(event.target.value)) {
            setItemsPerPage(10)
            return
        }
        setItemsPerPage(Number.parseInt(event.target.value))
    }

    const fields: Field<PatientPGD>[] = [
        {
            searchable: true,
            sortable: true,
            label: t('nhcIdPatient'),
            name: 'idPatient',
            renderFunc: (field, item) => patiens.get(item.id)?.idPatient || '',
        },
    
        {
            searchable: true,
            sortable: true,
            label: t('firstName'),
            name: 'firstName',
        },
        {
            searchable: true,
            sortable: true,
            label: t('lastName'),
            name: 'lastName',
        },
        {
            searchable: true,
            sortable: true,
            label: t('nhcIdPatientPartner'),
            name: 'idPatientPartner',
            
        },
        {
            searchable: true,
            sortable: true,
            label: t('firstNamePartner'),
            name: 'firstNamePartner',
        },
        {
            searchable: true,
            sortable: true,
            label: t('lastNamePartner'),
            name: 'lastNamePartner',
        },
        {
            searchable: true,
            label: t('clinicName'),
            name: 'clinicName',
        },
 
    ]

    const viewPatient = (patient: PatientPGD) => navigate(URL_PATIENT_PGSEC_SHOW.replace(':id', `${patient.id}`))
    const editPatient = (patient: PatientPGD) => navigate(URL_PATIENT_PGSEC.replace(":id", patient.id))

    const handleDelete = (patientPGD: PatientPGD) => {
        setCurrentPatientPGD(patientPGD)
        setOpen(true)
    }
    const actions: Actions<PatientPGD> = {
        actionsColumn: t('Actions'),
        items: [
            {
                handler: viewPatient,
                icon: seeIcon,
                label: t('Show'),
            },
            {
                handler: editPatient,
                icon: editIcon,
                label: t('Edit'),
                hidden: () => !authService.userCan(Permission.editPatients),
            },
            {
                handler: handleDelete,
                icon: deleteIcon,
                label: t('Delete'),
                hidden: () => !authService.userCan(Permission.deletePatients),
            },
        ],
    }


    const search: Search<PatientQuery> = {
        searchValues: searcher,
        handleSearch: (svs: SearchValue<PatientQuery>[]) => {
            const result: SearchValue<PatientQuery>[] = []
            svs.forEach((s) => {
                if (s.type === 'date' && s.value) {
                    result.push(Object.assign({...s}, {value: new Date(s.value as string).toISOString()}))
                } else {
                    result.push(s)
                }
            })
            setSearcher(result)
            setIsLoading(true)
        },
    }

    const sortable: Sort<PatientPGD> = {
        name: sort.field,
        direction: sort.desc ? 'desc' : 'asc',
        handleSort: (field) => {
            setSort({field: field, desc: sort.field === field ? !sort.desc : true})
            setIsLoading(true)
        },
    }

    const deleteConfirmed = () => {
        patientService.delete(currentPatientPGD?.id || '').subscribe(()=> {
            setCurrentPatientPGD(undefined)
            setIsLoading(true)
        })
    } 

    const createPatient = () => navigate(URL_PATIENT_PGSEC.replace(':id', 'create'))
    const closeModal = () => {
        setOpen(false)
        setCurrentPatientPGD(undefined)
    }

    const actionsDelete: FormAction[] = [
        {
            label: t('close'),
            handleAction: closeModal
        }
    ]

    const styles = commonStyles()

    return (
        <>
            <Box justifyContent={'space-between'} mb={2}>
                {authService.userCan(Permission.editPatients) && (
                    <Button
                        className={classes.button}
                        variant={'contained'}
                        onClick={createPatient}
                        style={{float: 'right'}}>
                        {t('new')}
                    </Button>
                )}
            </Box>
            <AppTable
                styleHeader={{color: COLOR_PRIMARY}}
                actions={actions}
                fields={fields}
                items={items}
                rowKeyField={'id'}
                pager={pager}
                search={search}
                sort={sortable}
            />
        <Modal open={open}>
        <Box className={styles.modal}>
          <form onSubmit={deleteConfirmed}>
            <Box mt={2} textAlign={'justify'}>
              {t('patientPGDConfirmationMessage')}
            </Box>
            <FormActions actions={actionsDelete} message={t('confirm')} />
          </form>
        </Box>
      </Modal>
        </>
    )
}