import {RouteProps} from '../../routes/AppRouter'
import {Box, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField,} from '@material-ui/core'
import {useForm} from '../../common/utils/form-generation/useForm'
import {useTranslation} from 'react-i18next'
import {navigate} from '@reach/router'
import {URL_PATIENT_PGD_SHOW, URL_PGD_TEST} from '../../routes/routes-constants'
import React, {useEffect, useState} from 'react'
import {v4 as uuidv4} from 'uuid'
import {FormAction, FormActions} from '../../common/utils/form-generation'
import {Query, QueryParam} from '../../common/api/Query'
import {emptyProjectDTO, Project, ProjectDTO} from '../../modules/projects/models/Project'
import {getProjectContainer} from '../../container/project-module'
import {ProjectService} from '../../modules/projects/services/ProjectService'
import {PROJECT_SERVICE_KEY} from '../../modules/projects'
import {ChildGenderPGD, GenderType} from '../../modules/users/enums/GenderType'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import {getFileContainer} from 'container/file-module'
import {FileService} from 'modules/files/services/FileService'
import {FILE_SERVICE_KEY} from 'modules/files'
import {Bedfile, Panel} from 'modules/files/models/File'
import {PatientService} from "../../modules/patients/services/PatientService";
import {PATIENT_SERVICE_KEY} from "../../modules/patients";
import {getPatientContainer} from "../../container/patient-module";
import {PatientPGD} from "../../modules/patients/models/PatientPGD";
import CircularProgress from "@mui/material/CircularProgress";

const projectService = getProjectContainer().get<ProjectService>(PROJECT_SERVICE_KEY)
const fileService = getFileContainer().get<FileService>(FILE_SERVICE_KEY)
const patientService = getPatientContainer().get<PatientService>(PATIENT_SERVICE_KEY)

export type FormProps = RouteProps & {
    project: Project | undefined
    patientID: string
}

export const Form = (props: FormProps) => {
    const {t} = useTranslation()
    const [panels, setPanels] = useState<Panel[]>([])
    const [bedfiles, setBedfiles] = useState<Bedfile[]>([])
    const [geneSelected, setGeneSelected] = useState<string>('')
    const [panelSelected, setPanelSelected] = useState<Panel>()
    const [panelsNames, setPanelsNames] = useState<string[]>([]);
    const [panelsOptions, setPanelsOptions] = useState<Panel[]>([])
    const [herencyPaternSelected, setHerencyPaternSelected] = useState<string>('')
    const [dataPatient, setDataPatient] = useState<PatientPGD>()
    const [dataPatientMale, setDataPatientMale] = useState<PatientPGD>()
    const [dataPatientFemale, setDataPatientFemale] = useState<PatientPGD>()
    const [showSpinner, setShowSpinner] = useState(false)

    useEffect(() => {
        if (!props.patientID) return
        patientService.getByIDPGD(props.patientID).subscribe((res) => {
            setDataPatient(res)
        })
    }, [])

    useEffect(() => {
        if (!dataPatient) return
        if (dataPatient.gender == GenderType.Male) {
            data.nameHim = dataPatient.firstName + " " + dataPatient.lastName
            dataPatient.dob && (data.dobHim = dataPatient.dob)
            data.nifHim = dataPatient.idPatient
            data.nifHer = dataPatient.idPatientPartner
            data.nameHer = dataPatient.firstNamePartner + " " + dataPatient.lastNamePartner
        } else {
            data.nameHer = dataPatient.firstName + " " + dataPatient.lastName
            dataPatient.dob && (data.dobHer = dataPatient.dob)
            data.nifHer = dataPatient.idPatient
            data.nifHim = dataPatient.idPatientPartner
            data.nameHim = dataPatient.firstNamePartner + " " + dataPatient.lastNamePartner
        }
        setShowSpinner(false)
    }, [dataPatient]);


    useEffect(() => {
        if (!geneSelected) return
        fileService
            .getBedfiles(
                new Query({
                    query: [new QueryParam('gene', geneSelected)],
                })
            )
            .subscribe((res) => {
                setBedfiles(
                    res.items.filter((bedfile) => {
                        const match = bedfile.fileName.match(
                            /^PGDSeq_(\w+)_(\w+)\.(\d+)\.(\w+)*/
                        )
                        return match && match[1] === geneSelected
                    })
                )
            })
    }, [geneSelected])

    useEffect(() => {
        fileService.getPanels(new Query({})).subscribe((res) => {

            let uniquePanelNames: string[] = []
            res.items.forEach((panel) => {
                if (!uniquePanelNames.includes(panel.gene)) {
                    uniquePanelNames.push(panel.gene)
                }
            })
            setPanels(res.items);
            setPanelsNames(uniquePanelNames);
        });
    }, []);


    useEffect(() => {
        if (!props.project) {
            return
        }

        fileService.getBedfileByID(props.project.toDTO().bedfileID).subscribe((res) => {
            res && setBedfiles([res])
        })
    }, [props.project])


    useEffect(() => {
        if (!props.project) {
            return
        }
        setData(props.project.toDTO())
    }, [props.project])


    const {handleChange, handleSubmit, data, setData, errors} = useForm<ProjectDTO>({
        validations: {
            name: {
                required: {
                    value: true,
                    message: t('projectNameNotValidError'),
                },
            },
        },

        onSubmit: () => {
            function storeCb(value: Project | undefined) {
                if (value) {
                    goToProyects().then()
                }
            }

            if (!props.project) {
                projectService
                    .add({
                        ...data,
                        id: uuidv4(),
                        pathology: panelSelected?.pathology || "",
                        childGender: ChildGenderPGD.Unknown,
                        lastGene: "0",
                        firstGene: "0",
                    })
                    .subscribe((value) => storeCb(value))
                return
            }


            projectService.update({...data}).subscribe((value) => storeCb(value))
        },

        initialValues: props.project ? props.project.toDTO() : emptyProjectDTO(props.patientID)
    })

    const goToProyects = () => navigate(URL_PGD_TEST)

    const actions: FormAction[] = [
        {
            label: t('back'),
            handleAction: () => goToProyects(),
        },
    ]

    const handleChangeSelectedPanel = (panelName: string) => {
        let selected = panels.filter((p) => p.gene == panelName)
        setGeneSelected(selected?.length > 0 ? selected[0].gene : "")

        setPanelsOptions(selected)
        if (selected.length === 1) {
            handleChange('panelID', selected[0].id)
            setHerencyPaternSelected(selected[0].herencyPatern)
        }
        setPanelSelected(selected[0])
        handleChange('panelID', selected[0].id)
    }

    const handleChangeHerencyPatern = (herencyPatern: string) => {
        let panel = panelsOptions.find((panel) => panel.id === herencyPatern)
        if (panel) {
            setHerencyPaternSelected(panel.herencyPatern)
            setPanelSelected(panel)
            handleChange('panelID', panel.id)
        }
    }

    return !showSpinner ? (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2} style={{marginBottom: "1%"}}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant={'outlined'}
                        error={errors['name'] !== undefined}
                        id={'name'}
                        type={'string'}
                        onChange={(event) => handleChange('name', event.target.value)}
                        value={(data && data.name) || ''}
                        label={t('projectName')}
                        helperText={errors['name']}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant={'outlined'}
                        error={errors['nameHer'] !== undefined}
                        id={'nameHer'}
                        type={'string'}
                        onChange={(event) => handleChange('nameHer', event.target.value)}
                        value={(data && data.nameHer) || ''}
                        label={t('nameHer')}
                        helperText={errors['nameHer']}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            style={{width: '100%'}}
                            id="dobHer"
                            autoOk
                            inputVariant={'outlined'}
                            format="dd/MM/yyyy"
                            value={new Date(data.dobHer) || null}
                            onChange={(newDate) => setData(Object.assign({...data}, {dobHer: newDate}))}
                            required={false}
                            size={'small'}
                            label={t('dobHer')}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant={'outlined'}
                        error={errors['nifHer'] !== undefined}
                        id={'nifHer'}
                        type={'string'}
                        onChange={(event) => handleChange('nifHer', event.target.value)}
                        value={(data && data.nifHer) || ''}
                        label={t('idPatient')}
                        helperText={errors['nifHer']}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant={'outlined'}
                        error={errors['nameHim'] !== undefined}
                        id={'nameHim'}
                        type={'string'}
                        onChange={(event) => handleChange('nameHim', event.target.value)}
                        value={(data && data.nameHim) || ''}
                        label={t('nameHim')}
                        helperText={errors['nameHim']}
                    />
                </Grid>

                <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            style={{width: '100%'}}
                            id="dobHim"
                            autoOk
                            inputVariant={'outlined'}
                            format="dd/MM/yyyy"
                            value={data.dobHim || null}
                            onChange={(newDate) => setData(Object.assign({...data}, {dobHim: newDate}))}
                            required={false}
                            size={'small'}
                            label={t('dobHim')}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant={'outlined'}
                        error={errors['nifHim'] !== undefined}
                        id={'nifHim'}
                        type={'string'}
                        onChange={(event) => handleChange('nifHim', event.target.value)}
                        value={(data && data.nifHim) || ''}
                        label={t('idPatient')}
                        helperText={errors['nifHim']}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" error={errors['panelID'] !== undefined}>
                        <InputLabel id="inherit-label">{t('panel')}</InputLabel>
                        <Select
                            labelId="panel"
                            id="panelID"
                            fullWidth
                            style={{textAlign: 'left'}}
                            value={ (panelSelected && panelSelected.gene) || (data && data.panelID)||''}
                            onChange={(event) => handleChangeSelectedPanel(event.target.value as string)}
                            label={t('panel')}>
                            {panelsNames.map((panel) => (
                                <MenuItem value={panel}>{panel}</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{errors['panelID']}</FormHelperText>
                    </FormControl>
                </Grid>
                {panelsOptions.length > 1 && (
                    <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined" error={errors['panelID'] !== undefined}>
                            <InputLabel id="inherit-label">{t('herencyPatern')}</InputLabel>
                            <Select
                                labelId="herencyPatern"
                                id="herencyPatern"
                                fullWidth
                                style={{textAlign: 'left'}}
                                value={(data && data.panelID) || ''}
                                onChange={(event) => handleChangeHerencyPatern(event.target.value as string)}
                                label={t('herencyPatern')}>
                                {panelsOptions.map((panel) => (
                                    <MenuItem value={panel.id}>{panel.herencyPatern}</MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{errors['panelID']}</FormHelperText>
                        </FormControl>
                    </Grid>
                )}
                {panelsOptions.length == 1 && (
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant={'outlined'}
                            error={errors['panelID'] !== undefined}
                            id={'herencyPatern'}
                            type={'string'}
                            value={(herencyPaternSelected)}
                            label={t('herencyPatern')}
                            disabled={true}
                            helperText={errors['panelID']}
                        />
                    </Grid>
                )
                }
                <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" error={errors['bedfileID'] !== undefined}>
                        <InputLabel id="inherit-label">{t('bedfile')}</InputLabel>
                        <Select
                            labelId="bedfileID"
                            id="bedfileID"
                            fullWidth
                            style={{textAlign: 'left'}}
                            value={(data && data.bedfileID) || ''}
                            onChange={(event) =>
                                handleChange('bedfileID', event.target.value as string)
                            }
                            label={t('panel')}>
                            {bedfiles.map((item) => (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{errors['bedfileID']}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant={'outlined'}
                        error={errors['pathology'] !== undefined}
                        id={'pathology'}
                        type={'string'}
                        value={(panelSelected && panelSelected.pathology) || ''}
                        label={t('herencyPatern')}
                        helperText={errors['pathology']}
                        inputProps={{
                            readOnly: true,
                            disabled: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            style={{width: '100%'}}
                            id="receptionDate"
                            autoOk
                            inputVariant={'outlined'}
                            format="dd/MM/yyyy"
                            value={data.receptionDate || null}
                            onChange={(newDate) =>
                                setData(Object.assign({...data}, {receptionDate: newDate}))
                            }
                            required={false}
                            size={'small'}
                            label={t('receptionDate')}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
            </Grid>
            <FormActions actions={actions}/>
        </form>
    ) : (
        <Box>
            <CircularProgress/>
        </Box>
    )
}
