import {RouteProps} from '../../../routes/AppRouter'
import {FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField,} from '@material-ui/core'
import {useForm} from '../../../common/utils/form-generation/useForm'
import {useTranslation} from 'react-i18next'
import {navigate} from '@reach/router'
import {URL_PATIENTS_PGD} from '../../../routes/routes-constants'
import {useEffect, useState} from 'react'
import {v4 as uuidv4} from 'uuid'
import {FormAction, FormActions} from '../../../common/utils/form-generation'
import {Query, QueryParam} from '../../../common/api/Query'
import {Clinic, ClinicQuery} from '../../../modules/clinics/models/Clinic'
import {getClinicContainer} from '../../../container/clinic-modules'
import {ClinicService} from '../../../modules/clinics/services/ClinicService'
import {CLINIC_SERVICE_KEY} from '../../../modules/clinics'
import {getPatientContainer} from '../../../container/patient-module'
import {PatientService} from '../../../modules/patients/services/PatientService'
import {PATIENT_SERVICE_KEY} from '../../../modules/patients'
import {EntitySelect} from '../../common/EntitySelect'
import {userGenders} from '../../../modules/users/enums/GenderType'
import {getAuthContainer} from '../../../container/auth-modules'
import {AuthService} from '../../../modules/auth/services/AuthService'
import {AUTH_SERVICE_KEY} from '../../../modules/auth'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import {Permission} from '../../../common/enums/Permissions'
import {Language, optionPatientLanguage} from '../../../modules/users/enums/Language'
import {PatientPGD, PatientPGDDTO} from 'modules/patients/models/PatientPGD'

const clinicService = getClinicContainer().get<ClinicService>(CLINIC_SERVICE_KEY)
const patientService = getPatientContainer().get<PatientService>(PATIENT_SERVICE_KEY)
const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)

export type FormProps = RouteProps & {
    patient: PatientPGD | undefined
}

export const Form = (props: FormProps) => {
    const {t} = useTranslation()
    const [clinics, setClinics] = useState<Clinic[]>([])

    useEffect(() => {
        if (!props.patient) {
            return
        }
        setData(props.patient.toDTO())
    }, [props.patient])

    useEffect(() => {
        const query = []
        if (!authService.get().permissions.includes(Permission.viewAll)) {
            query.push(new QueryParam<ClinicQuery>('ids', authService.get().clinics))
        } else {
            clinicService.getAllClinics().subscribe((res) => setClinics(res.items))
        }
        clinicService
            .getFilteredList(
                new Query({
                    query,
                    sort: [{field: 'name'}],
                })
            )
            .subscribe((res) => {
                setClinics(res.items)
            })
    }, [])



    const {handleChange, handleSubmit, data, setData, errors} = useForm<PatientPGDDTO>({
        validations: {
            clinicID: {
                required: {
                    value: true,
                    message: t('clinicNotValidError'),
                },
            },
            idPatient: {
                required: {
                    value: true,
                    message: t('nhcIdPatientNotValidError'),
                },
            },
            firstNamePartner: {
                required: {
                    value: true,
                    message: t('firstNamePartnerNotValidError'),
                },
            },
            lastNamePartner: {
                required: {
                    value: true,
                    message: t('lastNamePartnerNotValidError'),
                },
            },
            idPatientPartner: {
                required: {
                    value: true,
                    message: t('nhcIdPatientPartnerNotValidError'),
                },
            },
            firstName: {
                required: {
                    value: true,
                    message: t('firstNameNotValidError'),
                },
            },
            lastName: {
                required: {
                    value: true,
                    message: t('lastNameNotValidError'),
                },
            },
            gender: {
                required: {
                    value: true,
                    message: t('genderNotValidError'),
                },
            },
            genderPartner: {
                required: {
                    value: true,
                    message: t('genderNotValidError'),
                },
            },
            language: {
                required: {
                    value: true,
                    message: t('languageNotValidError'),
                },
            },
            clinicName: {
                required: {
                    value: true,
                    message: t('clinicNameNotValidError'),
                },
            },
        },

        onSubmit: () => {
            function storeCb(value: PatientPGD | undefined) {
                if (value) {
                    goToPatientsPGD().then()
                }
            }

            if (!data.id) {
                patientService
                    .addPGD({
                        ...data,
                        id: uuidv4(),
                        sampleName: data.firstName + data.lastName + 'PGD',
                        pgsec: false,
                        pgd: true,
                    })
                    .subscribe((value) => storeCb(value))
                return
            }

            patientService.updatePGD({...data, sampleName: data.firstName + data.lastName + 'PGD', pgsec: false,
                pgd: true,}).subscribe((value) => storeCb(value))
        },

        initialValues: props.patient && props.patient.toDTO(),
    })

    const goToPatientsPGD = () => navigate(URL_PATIENTS_PGD)

    const actions: FormAction[] = [
        {
            label: t('back'),
            handleAction: () => goToPatientsPGD(),
        },
    ]

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <EntitySelect
                        name={'name'}
                        value={data && data.clinicID}
                        options={clinics}
                        error={errors['clinicID']}
                        onChange={(value) => handleChange('clinicID', value && value.id)}
                        label={t('clinic') + ' *'}
                        pk={'id'}
                    />
                </Grid>

                <Grid container xs={12} justify='space-around'>

                    <Grid container xs={6} spacing={2}>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant={'outlined'}
                                error={errors['firstName'] !== undefined}
                                id={'firstName'}
                                type={'string'}
                                onChange={(event) => handleChange('firstName', event.target.value)}
                                value={(data && data.firstName) || ''}
                                label={t('firstName') + ' *'}
                                helperText={errors['firstName']}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant={'outlined'}
                                error={errors['lastName'] !== undefined}
                                id={'lastName'}
                                type={'string'}
                                onChange={(event) => handleChange('lastName', event.target.value)}
                                value={(data && data.lastName) || ''}
                                label={t('lastName') + ' *'}
                                helperText={errors['lastName']}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    style={{width: '100%'}}
                                    id="dob"
                                    autoOk
                                    inputVariant={'outlined'}
                                    format="dd/MM/yyyy"
                                    value={data.dob || null}
                                    onChange={(newDate) => setData(Object.assign({...data}, {dob: newDate}))}
                                    required={false}
                                    size={'small'}
                                    label={t('dob')}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant={'outlined'}
                                error={errors['idPatient'] !== undefined}
                                id={'name'}
                                type={'string'}
                                onChange={(event) => handleChange('idPatient', event.target.value)}
                                value={(data && data.idPatient) || ''}
                                label={t('nhcIdPatient') + ' *'}
                                helperText={errors['idPatient']}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant={'outlined'}
                                error={errors['sampleType'] !== undefined}
                                id={'sampleType'}
                                type={'string'}
                                onChange={(event) => handleChange('sampleType', event.target.value)}
                                value={(data && data.sampleType) || ''}
                                label={t('sampleType') + ' *'}
                                helperText={errors['sampleType']}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    style={{width: '100%'}}
                                    id="dateSampleType"
                                    autoOk
                                    inputVariant={'outlined'}
                                    format="dd/MM/yyyy"
                                    value={data.dateSampleType || null}
                                    onChange={(newDate) => setData(Object.assign({...data}, {dateSampleType: newDate}))}
                                    required={false}
                                    size={'small'}
                                    label={t('dateSampleType')}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined" error={errors['gender'] !== undefined}>
                                <InputLabel id="inherit-label">{t('gender') + ' *'}</InputLabel>
                                <Select
                                    labelId="gender-label"
                                    id="gender"
                                    fullWidth
                                    style={{textAlign: 'left'}}
                                    value={(data && data.gender) || ''}
                                    onChange={(event) =>
                                        handleChange('gender', event.target.value && +(event.target.value as string))
                                    }
                                    label={t('gender')}>
                                    {Object.entries(userGenders()).map(([key, value]) => (
                                        <MenuItem value={key}>{value}</MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{errors['gender']}</FormHelperText>
                            </FormControl>
                        </Grid>

                    </Grid>

                    <Grid container xs={6} spacing={2}>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant={'outlined'}
                                error={errors['firstNamePartner'] !== undefined}
                                id={'name'}
                                type={'string'}
                                onChange={(event) => handleChange('firstNamePartner', event.target.value)}
                                value={(data && data.firstNamePartner) || ''}
                                label={t('firstNamePartner') + ' *'}
                                helperText={errors['firstNamePartner']}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant={'outlined'}
                                error={errors['lastNamePartner'] !== undefined}
                                id={'lastNamePartner'}
                                type={'string'}
                                onChange={(event) => handleChange('lastNamePartner', event.target.value)}
                                value={(data && data.lastNamePartner) || ''}
                                label={t('lastNamePartner') + ' *'}
                                helperText={errors['lastNamePartner']}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    style={{width: '100%'}}
                                    id="dobPartner"
                                    autoOk
                                    inputVariant={'outlined'}
                                    format="dd/MM/yyyy"
                                    value={data.dobPartner || null}
                                    onChange={(newDate) => setData(Object.assign({...data}, {dobPartner: newDate}))}
                                    required={false}
                                    size={'small'}
                                    label={t('dobPartner')}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant={'outlined'}
                                error={errors['idPatientPartner'] !== undefined}
                                id={'idPatientPartner'}
                                type={'string'}
                                onChange={(event) => handleChange('idPatientPartner', event.target.value)}
                                value={(data && data.idPatientPartner) || ''}
                                label={t('nhcIdPatientPartner') + ' *'}
                                helperText={errors['idPatientPartner']}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant={'outlined'}
                                error={errors['sampleTypePartner'] !== undefined}
                                id={'sampleTypePartner'}
                                type={'string'}
                                onChange={(event) => handleChange('sampleTypePartner', event.target.value)}
                                value={(data && data.sampleTypePartner) || ''}
                                label={t('sampleTypePartner') + ' *'}
                                helperText={errors['sampleTypePartner']}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    style={{width: '100%'}}
                                    id="dateSampleTypePartner"
                                    autoOk
                                    inputVariant={'outlined'}
                                    format="dd/MM/yyyy"
                                    value={data.dateSampleTypePartner || null}
                                    onChange={(newDate) => setData(Object.assign({...data}, {dateSampleTypePartner: newDate}))}
                                    required={false}
                                    size={'small'}
                                    label={t('dateSampleTypePartner')}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined" error={errors['genderPartner'] !== undefined}>
                                <InputLabel id="inherit-label">{t('genderPartner') + ' *'}</InputLabel>
                                <Select
                                    labelId="gender-label"
                                    id="genderPartner"
                                    fullWidth
                                    style={{textAlign: 'left'}}
                                    value={(data && data.genderPartner) || ''}
                                    onChange={(event) =>
                                        handleChange('genderPartner', event.target.value && +(event.target.value as string))
                                    }
                                    label={t('genderPartner')}>
                                    {Object.entries(userGenders()).map(([key, value]) => (
                                        <MenuItem value={key}>{value}</MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{errors['gender']}</FormHelperText>
                            </FormControl>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant={'outlined'}
                        error={errors['clinicName'] !== undefined}
                        id={'name'}
                        type={'string'}
                        onChange={(event) => handleChange('clinicName', event.target.value)}
                        value={(data && data.clinicName) || ''}
                        label={t('clinicName') + ' *'}
                        helperText={errors['clinicName']}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" error={errors['language'] !== undefined}>
                        <InputLabel id="language-label">{t('language') + ' *'}</InputLabel>
                        <Select
                            labelId="language-label"
                            id="language"
                            fullWidth
                            style={{textAlign: 'left'}}
                            value={(data && data.language) || ''}
                            onChange={(event) => handleChange('language', event.target.value)}
                            label={t('language')}>
                            {Object.keys(optionPatientLanguage()).filter((e) => e === Language.English).map((l) => ( //FIXME remove filter when we have all reports docx
                                <MenuItem value={l} key={l}>
                                    {t(l)}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{errors['language']}</FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
            <FormActions actions={actions}/>
        </form>
    )
}
