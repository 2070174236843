import { RouteProps } from '../../routes/AppRouter'
import {
  Box,
  Button,
  createStyles,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Switch,
  TextField,
} from '@material-ui/core'
import { useForm } from '../../common/utils/form-generation/useForm'
import { useTranslation } from 'react-i18next'
import { navigate } from '@reach/router'
import { URL_USERS } from '../../routes/routes-constants'
import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { FormAction, FormActions } from '../../common/utils/form-generation'
import { getUserContainer } from '../../container/user-modules'
import { UserService } from '../../modules/users/services/UserService'
import { USER_SERVICE_KEY } from '../../modules/users'
import { User, UserDTO } from '../../modules/users/models/User'
import { clinicUserRoles, RoleType, roleTypes } from '../../modules/users/enums/RoleType'
import { Clinic, ClinicQuery } from '../../modules/clinics/models/Clinic'
import { getClinicContainer } from '../../container/clinic-modules'
import { ClinicService } from '../../modules/clinics/services/ClinicService'
import { CLINIC_SERVICE_KEY } from '../../modules/clinics'
import { Query, QueryParam } from '../../common/api/Query'
import { EntityMultipleSelect } from 'features/common'
import { ChangePasswordDTO } from '../../modules/auth/models/ChangePassword'
import { Lock } from '@material-ui/icons'
import { getAuthContainer } from '../../container/auth-modules'
import { AuthService } from '../../modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from '../../modules/auth'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useStyles } from '../../components/entity-page/EntityPage.styles'
import { optionLanguage } from '../../modules/users/enums/Language'
import { Permission } from '../../common/enums/Permissions'
import { commonStyles } from '../../common/styles/Styles'
import { EntitySelect } from '../common/EntitySelect'

const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)
const userService = getUserContainer().get<UserService>(USER_SERVICE_KEY)
const clinicService = getClinicContainer().get<ClinicService>(CLINIC_SERVICE_KEY)

export type FormProps = RouteProps & {
  user: User | undefined
}

export const Form = (props: FormProps) => {
  const { t } = useTranslation()

  const classes = useStyles({ color: props.color })

  const roles = authService.get().permissions.find((p) => p === Permission.editAllUsers)
    ? roleTypes()
    : clinicUserRoles()

  const useClass = makeStyles((theme: Theme) =>
    createStyles({
      modal: {
        position: 'absolute',
        width: 600,
        backgroundColor: theme.palette.background.paper,
        borderRadius: '4px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 0),
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      },
    })
  )

  const formClass = useClass()
  const styles = commonStyles()

  const [clinics, setClinics] = useState<Clinic[]>([])
  const [passwordModal, setPasswordModal] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    if (props.user) {
      setData(props.user.toDTO())
      setIsLoading(false)
    } else {
      setIsLoading(false)
    }
  }, [props.user])

  useEffect(() => {
    const query: QueryParam<ClinicQuery>[] = []
    if (!authService.get().permissions.includes(Permission.viewAll)) {
      query.push(new QueryParam<ClinicQuery>('ids', authService.get().clinics))
    } else {
      clinicService.getAllClinics().subscribe((res) => setClinics(res.items))
    }
    clinicService
      .getFilteredList(
        new Query({
          query,
          sort: [{ field: 'name' }],
        })
      )
      .subscribe((res) => {
        setClinics(res.items)
      })
  }, [])

  const { handleChange, handleSubmit, data, setData, errors } = useForm<UserDTO>({
    
    validations: {
      firstName: {
        required: {
          value: true,
          message: t('firstNameNotValidError'),
        },
      },
      lastName: {
        required: {
          value: true,
          message: t('lastNameNotValidError'),
        },
      },
      email: {
        pattern: {
          value: '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$',
          message: t('emailNotValidError'),
        },
      },
      phone: {
        pattern: {
          value: '(^$|^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$)',
          message: t('phoneNotValidError'),
        },
      },
      role: {
        required: {
          value: true,
          message: t('roleNotValidError'),
        },
      },
      clinics: {
        required: {
          value: false,
          message: t('clinicNotValidError'),
        },
      },
    },

    onSubmit: () => {
      if(data.role === RoleType.BAAdmin || data.role === RoleType.BAUser){
        
        data.pgd = true
        data.pgsec = true
        data.receptiveness = true
      }
      if(data.clinics){
      if (data.role === RoleType.ClinicAdmin || data.role === RoleType.ClinicUser ) {
        //setear data.pgd, data.pgsec, data.receptiveness filtrando por  data.clinics en clinics
        const clinic = clinics.find((c) => c.id === data.clinics[0])
        data.pgd = clinic?.activePgd || false
        data.pgsec = clinic?.activePgsec || false
        data.receptiveness = clinic?.activeReceptiveness || false
      }
    }
      data.createdAt = new Date()

      if (!data.id) {
        userService.add({ ...data, id: uuidv4() }).subscribe(() => goToUsers())
        return
      }
      userService.update(data).subscribe(() => goToUsers())
    },

    initialValues: props.user && props.user.toDTO(),
  })

  const isPasswordValid = (value: string): boolean => dataPassword.newPassword === value

  const {
    handleChange: handleChangePassword,
    handleSubmit: handleSubmitPassword,
    data: dataPassword,
    setData: setDataPassword,
    errors: errorsPassword,
  } = useForm<ChangePasswordDTO>({
    validations: {
      newPassword: {
        required: {
          value: true,
          message: t('newPasswordIsRequired'),
        },
        pattern: {
          value: '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$',
          message: t('passwordRequirements'),
        },
      },
      confirmNewPassword: {
        required: {
          value: true,
          message: t('repeatNewPasswordIsRequired'),
        },
        custom: {
          isValid: isPasswordValid,
          message: t('passwordsDoesntMatch'),
        },
      },
    },

    onSubmit: () => {
      dataPassword.id = data.id
      authService.changePassword(dataPassword).subscribe(() => setPasswordModal(false))
    },
  })

  const goToUsers = () => navigate(URL_USERS)

  const actions: FormAction[] = [
    {
      label: t('back'),
      handleAction: () => goToUsers(),
    },
  ]

  const actionsPassword: FormAction[] = [
    {
      label: t('close'),
      handleAction: () => setPasswordModal(false),
    },
  ]

  return (
    <form onSubmit={handleSubmit}>
      {!isLoading && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant={'outlined'}
              error={errors['firstName'] !== undefined}
              id={'firstName'}
              type={'string'}
              onChange={(event) => handleChange('firstName', event.target.value)}
              value={(data && data.firstName) || ''}
              label={t('firstName') + ' *'}
              helperText={errors['firstName']}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant={'outlined'}
              error={errors['lastName'] !== undefined}
              id={'lastName'}
              type={'string'}
              onChange={(event) => handleChange('lastName', event.target.value)}
              value={(data && data.lastName) || ''}
              label={t('lastName') + ' *'}
              helperText={errors['lastName']}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={errors['email'] !== undefined}
              fullWidth
              variant={'outlined'}
              id="email"
              type={'email'}
              label={t('email') + ' *'}
              onChange={(event) => handleChange('email', event.target.value)}
              value={data && data.email}
              helperText={errors['email']}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={errors['phone'] !== undefined}
              fullWidth
              variant={'outlined'}
              id="phone"
              type={'phone'}
              label={t('phone') + ' *'}
              onChange={(event) => handleChange('phone', event.target.value)}
              value={data && data.phone}
              helperText={errors['phone']}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
         
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined" error={errors['role'] !== undefined}>
              <InputLabel id="role-label">{t('role') + ' *'}</InputLabel>
              <Select
                labelId="inherit-label"
                id="inherit"
                fullWidth
                style={{ textAlign: 'left' }}
                value={(data && data.role) || ''}
                onChange={(event) => handleChange('role', +(event.target.value as RoleType))}
                label={t('role')}>
                {Object.entries(roles).map(([key, value]) => (
                  <MenuItem value={key}>{value}</MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors['role']}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {(data.role === RoleType.ClinicUser || data.role === RoleType.ClinicAdmin) && (
              <EntitySelect
                name={'name'}
                value={data && data.clinics && data.clinics[0]}
                options={clinics}
                onChange={(value) => handleChange('clinics', [value && value.id])}
                label={t('Clinics')}
                pk={'id'}
              />
            )}
            
          </Grid>
          {/*
          <Grid item xs={12} style={{ justifyContent: 'flex-start', display: 'flex' }}>
            <FormControlLabel
              className={styles.toggle}
              control={<Switch defaultChecked />}
              label={t('receptiveness')}
              checked={data.receptiveness == true}
              style={{ marginLeft: '0.2%' }}
              onChange={() => handleChange('receptiveness', !data.receptiveness)}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ justifyContent: 'flex-start', display: 'flex', marginBottom: '2%' }}>
            <FormControlLabel
              className={styles.toggle}
              control={<Switch defaultChecked />}
              label={t('pgdseq')}
              style={{ marginLeft: '0.2%' }}
              checked={data.pgd == true}
              onChange={() => handleChange('pgd', !data.pgd)}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ justifyContent: 'flex-start', display: 'flex', marginBottom: '2%' }}>
            <FormControlLabel
              className={styles.toggle}
              control={<Switch defaultChecked />}
              label={t('pgsec')}
              style={{ marginLeft: '0.2%' }}
              checked={data.pgsec == true}
              onChange={() => handleChange('pgsec', !data.pgsec)}
            />
          </Grid>
          */}
          {!data.id && (
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" error={errors['language'] !== undefined}>
                <InputLabel id="language-label">{t('language')}</InputLabel>
                <Select
                  labelId="language-label"
                  id="language"
                  fullWidth
                  style={{ textAlign: 'left' }}
                  value={(data && data.language) || ''}
                  onChange={(event) => handleChange('language', event.target.value)}
                  label={t('language')}>
                  {Object.keys(optionLanguage()).map((l) => (
                    <MenuItem value={l} key={l}>
                      {t(l)}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors['language']}</FormHelperText>
              </FormControl>
            </Grid>
          )}
        </Grid>
      )}
      <FormActions actions={actions} />
      {data.id && (
        <Box style={{ textAlign: 'start' }}>
          <Button
            style={{ width: '205px' }}
            className={classes.button}
            onClick={() => setPasswordModal(true)}
            variant={'contained'}>
            {t('changePassword')}
          </Button>
        </Box>
      )}
      <Modal open={passwordModal}>
        <Box className={formClass.modal}>
          <form onSubmit={handleSubmitPassword}>
            <Box mt={2}>
              <FormControl
                fullWidth
                variant={'outlined'}
                error={errorsPassword['newPassword'] !== undefined}>
                <TextField
                  fullWidth
                  variant={'outlined'}
                  onChange={(event) => handleChangePassword('newPassword', event.target.value)}
                  name={'newPassword'}
                  label={t('newPassword')}
                  type={'password'}
                  id={'newPassword'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment color={'inherit'} position={'start'}>
                        <Lock color={'inherit'} />
                      </InputAdornment>
                    ),
                  }}
                />
                <FormHelperText>{errorsPassword['newPassword']}</FormHelperText>
              </FormControl>
            </Box>
            <Box mt={2}>
              <FormControl
                fullWidth
                variant={'outlined'}
                error={errorsPassword['confirmNewPassword'] !== undefined}>
                <TextField
                  fullWidth
                  variant={'outlined'}
                  onChange={(event) =>
                    handleChangePassword('confirmNewPassword', event.target.value)
                  }
                  name={'confirmNewPassword'}
                  label={t('confirmNewPassword')}
                  type={'password'}
                  id={'confirmNewPassword'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment color={'inherit'} position={'start'}>
                        <Lock color={'inherit'} />
                      </InputAdornment>
                    ),
                  }}
                />
                <FormHelperText>{errorsPassword['confirmNewPassword']}</FormHelperText>
              </FormControl>
            </Box>
            <FormActions actions={actionsPassword} />
          </form>
        </Box>
      </Modal>
    </form>
  )
}
