import { v4 as uuidv4 } from 'uuid'
import { RoleType } from '../enums/RoleType'
import { Language } from '../enums/Language'

export interface UserDTO {
  id: string
  idAuth: string
  firstName: string
  lastName: string
  createdAt: Date
  email: string
  phone: string
  disabled: boolean
  language: string
  role: RoleType
  clinics: string[]
  initPass: boolean
  receptiveness: boolean
  pgd: boolean
  pgsec: boolean

}

export function emptyUserDTO(): UserDTO {
  return {
    id: uuidv4(),
    idAuth: '',
    firstName: '',
    lastName: '',
    createdAt: new Date(),
    email: '',
    phone: '',
    disabled: false,
    language: Language.Spanish,
    role: RoleType.BAAdmin,
    clinics: [],
    initPass: true,
    receptiveness: true,
    pgd: true,
    pgsec: true
  }
}

export class User {
  private readonly _id: string
  private readonly _idAuth: string
  private readonly _firstName: string
  private readonly _lastName: string
  private readonly _createdAt: Date
  private readonly _email: string
  private readonly _phone: string
  private readonly _disabled: boolean
  private readonly _language: string
  private readonly _role: RoleType
  private readonly _clinics: string[]
  private readonly _initPass: boolean
  private readonly _receptiveness: boolean
  private readonly _pgd: boolean
  private readonly _pgsec: boolean

  constructor(p: UserDTO) {
    this._id = p.id || uuidv4()
    this._idAuth = uuidv4()
    this._firstName = p.firstName
    this._lastName = p.lastName
    this._createdAt = p.createdAt
    this._email = p.email
    this._phone = p.phone || ''
    this._disabled = p.disabled
    this._language = p.language
    this._role = p.role
    this._clinics = p.clinics || []
    this._initPass = p.initPass
    this._receptiveness = p.receptiveness
    this._pgd = p.pgd
    this._pgsec = p.pgsec
  }

  get id(): string {
    return this._id
  }

  get idAuth(): string {
    return this._idAuth
  }

  get firstName(): string {
    return this._firstName
  }

  get lastName(): string {
    return this._lastName
  }

  get createdAt(): Date {
    return this._createdAt
  }

  get email(): string {
    return this._email
  }

  get phone(): string {
    return this._phone
  }

  get disabled(): boolean {
    return this._disabled
  }

  get language(): string {
    return this._language
  }

  get role(): RoleType {
    return this._role
  }

  get clinics(): string[] {
    return this._clinics
  }

  get receptiveness(): boolean {
    return this._receptiveness
  }

  get pgd(): boolean {
    return this._pgd
  }

  get pgsec(): boolean {
    return this._pgsec
  }

  get initPass(): boolean {
    return this._initPass
  }

  toDTO(): UserDTO {
    return {
      id: this.id,
      idAuth: this.idAuth,
      firstName: this.firstName,
      lastName: this.lastName,
      createdAt: this.createdAt,
      email: this.email,
      phone: this.phone,
      disabled: this.disabled,
      language: this.language,
      role: this.role,
      clinics: this.clinics,
      initPass: this.initPass,
      receptiveness: this.receptiveness,
      pgd: this.pgd,
      pgsec: this.pgsec
    }
  }
}

export interface UserQuery {
  id: string
  clinics: string[]
  role: RoleType
}

export function toModel(d: UserDTO): User {
  return new User(d)
}
