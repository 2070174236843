export const dataToBase64 = async (f: File): Promise<string> => {
  try {
    const arrayBuffer = await f.arrayBuffer();
    const uint8Array = new Uint8Array(arrayBuffer);
    
    const base64String = btoa(
      uint8Array.reduce((data, byte) => data + String.fromCharCode(byte), '')
    );
    
    return base64String;
  } catch (error) {
    console.error('Error converting file to base64:', error);
    throw new Error('Failed to convert file to base64');
  }
};

export const dataToBase642 = async (f: File): Promise<{ base64: string; error: Error | null }> => {
  try {
    const arrayBuffer = await f.arrayBuffer();
    const uint8Array = new Uint8Array(arrayBuffer);

    // Check if the file is too large
    if (uint8Array.length > 900_000_000) {
      throw new Error('File size exceeds 900 MB');
    }

    // Convert Uint8Array to base64 string
    const base64String = btoa(
      Array.from(uint8Array).reduce((data, byte) => data + String.fromCharCode(byte), '')
    );

    return { base64: base64String, error: null };
  } catch (error) {
    console.error('Error converting file to base64:', error);
    return { base64: '', error: error instanceof Error ? error : new Error('Unknown error') };
  }
};

export function downloadFile(name: string, mimeType: string, data: string) {
  const f = atob(data)
  const length = f.length
  const ab = new ArrayBuffer(length)
  const ua = new Uint8Array(ab)
  for (let i = 0; i < length; i++) {
    ua[i] = f.charCodeAt(i)
  }
  const blob = new Blob([ab], { type: mimeType })

  //@ts-ignore
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //@ts-ignorez
    window.navigator.msSaveOrOpenBlob(blob, name)
    return
  } else {
    console.log("La propiedad navigator.msSaveOrOpenBlob no está disponible.");
  }
  const blobURL = URL.createObjectURL(blob)
  const link = document.createElement('a')

  link.href = blobURL
  link.download = name
  link.setAttribute('download', name)

  if (!link.download) {
    link.setAttribute('target', '_blank')
  }
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)

  setTimeout(() => {
    window.URL.revokeObjectURL(blobURL)
  }, 100)
}

