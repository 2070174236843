import {navigate, RouteComponentProps} from '@reach/router'
import {useTranslation} from 'react-i18next'
import React from 'react'
import {IAuthService} from 'modules/auth/services/AuthService'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import {Card, CardContent, InputAdornment, Snackbar} from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'
import {Lock} from '@material-ui/icons'
import {useForm} from '../../common/utils/form-generation/useForm'
import {LoginButton} from './components/Button'
import {LoginTextField} from './components/TextField'
import {URL_PATIENTS, URL_RECOVER_PASSWORD, URL_REGISTER} from '../../routes/routes-constants'
import {useStyles} from './Login.styles'
import {AUTH_SERVICE_KEY} from '../../modules/auth'
import {getAuthContainer} from '../../container/auth-modules'
import {Alert} from '@material-ui/lab'
import {Error} from '../../modules/users/models/Error'

const authService = getAuthContainer().get<IAuthService>(AUTH_SERVICE_KEY)

interface FormData {
    email: string
    password: string
}

function LoginForm() {
    const classes = useStyles()
    const {t} = useTranslation()
    const [open, setOpen] = React.useState<boolean>(false)
    const [err, setError] = React.useState<string>('')

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }

        setOpen(false)
    }

    const {handleSubmit, handleChange, data, errors} = useForm<FormData>({
        validations: {
            email: {
                pattern: {
                    value: '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$',
                    message: t('emailNotValidError'),
                },
            },
            /*      password: {
              pattern: {
                value: '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$',
                message: t('passwordRequirements'),
              },
            },*/
        },

        onSubmit: () => {
            authService.login({login: data.email, password: data.password}).subscribe((res) => {
                if (Object.keys(res).find((k) => (k === 'message' || k === 'sql: no rows in result set\n'))) {
                    setError((res as Error).message)
                    setOpen(true)
                } else {
                    navigate(URL_PATIENTS).then()
                }
            })
        },
    })

    const handleForgotPassword = () => navigate(URL_RECOVER_PASSWORD)

    const snackbarFunction = (err: string) => (
        <div>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                style={{
                    position: 'absolute',
                    left: '50%',
                    marginLeft: 'auto',
                }}>
                <Alert
                    onClose={handleClose}
                    severity={'error'}
                    style={{
                        marginLeft: 'initial',
                    }}>
                    {err === 'incorrect login o password' && t('loginIncorrect')}
                    {err === 'unauthorized login' && t('unauthorizedLogin')}
                    {err === 'sql: no rows in result set\n' && t('serverNotConnect')}
                </Alert>
            </Snackbar>
        </div>
    )

    return (
        <Container className={classes.containerForm}>
            <Typography align={'left'} component={'h1'} variant={'h5'}>
                {window.navigator.language === 'es-ES' ? t('login') : 'Login'}
            </Typography>
            <Typography align={'left'} component={'h2'} variant={'subtitle1'}>
                {window.navigator.language === 'es-ES' ?  t('introduceUserData') : 'Enter your user data'}
            </Typography>
            <form className={classes.form} autoComplete={'off'} onSubmit={handleSubmit}>
                <LoginTextField
                    variant={'filled'}
                    margin={'normal'}
                    required
                    fullWidth
                    id={'email'}
                    onChange={(event) => handleChange('email', event.target.value)}
                    placeholder={t('email')}
                    name={'email'}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment color={'inherit'} position={'start'}>
                                <PersonIcon color={'inherit'}/>
                            </InputAdornment>
                        ),
                    }}
                    autoComplete={'off'}
                />
                {errors.email && <p className={'error'}>{errors.email}</p>}
                <LoginTextField
                    variant={'filled'}
                    margin={'normal'}
                    required
                    fullWidth
                    onChange={(event) => handleChange('password', event.target.value)}
                    name={'password'}
                    placeholder={window.navigator.language === 'es-ES' ?  t('password') : 'Password'}
                    type={'password'}
                    id={'password'}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment color={'inherit'} position={'start'}>
                                <Lock color={'inherit'}/>
                            </InputAdornment>
                        ),
                    }}
                />
                {errors.password && <p className={'error'}>{errors.password}</p>}
                <Grid container alignItems={'center'} justify={'space-between'}>
                    <Grid item xs={3}>
                        <LoginButton
                            type={'submit'}
                            fullWidth
                            variant={'contained'}
                            color={'primary'}
                            className={classes.loginButton}>
                            {window.navigator.language === 'es-ES' ?  t('login') : 'login'}
                        </LoginButton>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography onClick={() => handleForgotPassword()} color={'inherit'} align={'right'}>
                            {window.navigator.language === 'es-ES' ? t('forgetYourPassword') : 'Did you forget your password?'}
                        </Typography>
                    </Grid>
                </Grid>
                <div className={classes.phraseBox}>
                       <p> {window.navigator.language === 'es-ES' ? t("loginPhrase") : "If you want to contact with us write to "}</p> 
                    <p className={classes.loginEmail}>support@journeygenomics.com</p>
                </div>
            </form>
            {snackbarFunction(err)}
        </Container>
    )
}

export const Login = (props: RouteComponentProps) => {
    const classes = useStyles()
    const {t} = useTranslation()

    const handleRegister = () => navigate(URL_REGISTER)

    return (
        <Container className={classes.container}>
            <Grid container className={classes.root} spacing={0} alignItems={'center'} justify={'center'}>
                <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                        <Grid alignItems={'stretch'} container spacing={0}>
                            <Grid item xs={7}>
                                <Grid item xs={12} className={classes.innerGrid}>
                                    <LoginForm/>
                                </Grid>
                            </Grid>
                            <Grid item xs={5} className={classes.registerCard}>
                                <Grid container justify={'center'} className={classes.innerGrid}>
                                    <img className={classes.logo} src={'/bioarray-login-logo.svg'}/>
                                    <LoginButton
                                        fullWidth={true}
                                        onClick={() => handleRegister()}
                                        className={classes.registerButton}>
                                        {window.navigator.language === 'es-ES' ? t('register') : 'Register'}
                                    </LoginButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Container>
    )
}
